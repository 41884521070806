export const mapCreateIssueLogDataForBackend = (data) => ({
  reporter_id: data.logged_in_userId,
  issue_service: data.service,
  order_specifics: data.order.item,
  order_service_date: data.order.service_date,
  order_id: data.order.order_id,
  ...data,
  affected_user_id: data.affected_user.id,
  gardener_id: data.gardener.id,
  resolvers_id: data.resolvers?.map((resolver) => resolver.id),
  riders_id: data.riders_id?.map((rider) => rider.id),
  service_provider_id: data.service_partner.id,
});

export const mapUpdateIssueLogDataForBackend = (data) => ({
  reporter_id: data.logged_in_userId,
  issue_service: data.service,
  department: data.department,
  resolved_by: data?.reporter_id,
  ...data,
  affected_user_id: data.affected_user?.id,
  gardener_id: data.gardener?.id,
  resolvers_id: data.resolvers?.map((resolver) => resolver.id),
  riders_id: data.riders_id?.map((rider) => rider.id),
  issue_id: data.issueId,
  service_provider_id: data.service_partner?.id,
});

<template>
  <el-drawer
    :title="`${formatText(action)} Issue`"
    :visible.sync="setShow"
    direction="rtl"
    append-to-body
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row v-if="!orderSpecificData" type="flex">
            <el-col :span="24">
              <eden-alert :type="'warning'">
                <span
                  >To add order-specific issues, go to the order itself and
                  report the issue from there.</span
                >
              </eden-alert>
            </el-col>
          </el-row>
          <!-- Field: Department -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Department"
                prop="department"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.department"
                  value-key="id"
                  placeholder="Select the related department"
                  @change="onSelectDepartment"
                >
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :value="department"
                  >
                    {{ department.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Field: Supporting Department -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Supporting Department"
                prop="supporting_departments"
              >
                <el-select
                  v-model="form.supporting_departments"
                  value-key="id"
                  placeholder="Select the related department"
                  multiple
                >
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :value="department"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- Service -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Service" prop="service">
                <el-select
                  v-model="form.service"
                  placeholder="Select the related service (if any)"
                >
                  <el-option
                    v-for="(service, index) in services"
                    :key="index"
                    :label="service.service_name"
                    :value="service.slug"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <template v-if="form.department">
            <!-- Order Type -->
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="What Type of Order is it?"
                  prop="order_type"
                >
                  <el-radio-group v-model="form.order_type">
                    <el-radio :label="'subscription'">Subscription</el-radio>
                    <el-radio :label="'one-time-order'">One-time</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Section: Issue -->
            <div class="specific-section issue">
              <div class="specific-section--header">
                <p>Issue details</p>
                <i class="el-icon-arrow-up" />
              </div>
              <div class="specific-section--content">
                <!-- Field: Issue Description -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Description"
                      prop="description"
                      :rules="validateField()"
                    >
                      <el-input
                        type="textarea"
                        rows="4"
                        v-model="form.description"
                        placeholder="What is the issue?"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Issue Title -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Title"
                      prop="title"
                      :rules="validateField()"
                    >
                      <el-input
                        type="text"
                        v-model="form.title"
                        placeholder=""
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Attachments -->
                <el-row type="flex">
                  <el-col :span="24">
                    <eden-attachments-upload
                      :attachments.sync="form.attachments"
                      :file-folder="'issues'"
                    />
                  </el-col>
                </el-row>
                <!-- Field: Issue Category -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Issue category"
                      prop="issue_category"
                      :rules="validateField()"
                    >
                      <el-select
                        v-model="form.issue_category"
                        placeholder="Select category"
                        multiple
                      >
                        <el-option
                          v-for="(category, index) in filteredCategories"
                          :key="index"
                          :label="category.name"
                          :value="category.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Rider -->
                <el-row v-if="showRiderSelection" type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Name of Rider"
                      prop="riders_id"
                      :rules="validateField()"
                    >
                      <eden-personnel-select
                        :personnel-type="'rider'"
                        :personnels.sync="form.riders_id"
                        :multiple="true"
                        :placeholder="
                          orderSpecificData
                            ? 'Select the rider'
                            : 'Select at least 1 rider'
                        "
                        returnObject
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Affect User Type [Customer or Humaan] -->
                <el-row v-if="showAffectedUserType" type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Is this a customer’s issue or a Humaan’s issue?"
                      prop="affected_customer_type"
                      :rules="validateField()"
                    >
                      <el-radio-group v-model="form.affected_customer_type">
                        <el-radio :label="'customer'">Customer</el-radio>
                        <el-radio :label="'humaan'">Humaan</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- Field: Affected Customers/Humaans -->
                <div>
                  <template v-if="form.affected_customer_type === 'customer'">
                    <el-row type="flex">
                      <el-col :span="24">
                        <el-form-item
                          :label="
                            form.order_type === ''
                              ? 'Affected customer'
                              : 'Customer'
                          "
                          prop="affected_user"
                          :rules="validateField()"
                        >
                          <eden-customers-select
                            :customer.sync="form.affected_user"
                            :multiple="false"
                            :placeholder="'Search and select the affected customer'"
                            return-object
                            search-by-name
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex">
                      <el-col :span="24">
                        <el-form-item
                          :label="
                            form.order_type === 'subscription' || !orderSpecific
                              ? 'Gardener of customer'
                              : 'Support Rep In Charge'
                          "
                          prop="gardener"
                          :rules="validateField()"
                        >
                          <eden-gardener-select
                            :gardener.sync="form.gardener"
                            returnObject
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <template v-else>
                    <el-row type="flex">
                      <el-col :span="24">
                        <el-form-item
                          label="Affected Humaans"
                          prop="other_affected_users"
                          :rules="validateField()"
                        >
                          <eden-personnel-select
                            :personnel-type="'member'"
                            :personnels.sync="form.other_affected_users"
                            :multiple="true"
                            :placeholder="'Select at least 1 humaan'"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                </div>

                <!-- Field: Other Affected Customers -->
                <template v-if="form.service === 'meal'">
                  <el-row type="flex">
                    <el-col :span="24">
                      <el-form-item
                        label="Are there any other customers affected by this particular issue?"
                        prop="other_affected_user"
                        :rules="validateField()"
                      >
                        <el-radio-group v-model="form.other_affected_user">
                          <el-radio :label="true">Yes</el-radio>
                          <el-radio :label="false">No</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="form.other_affected_user" type="flex">
                    <el-col :span="24">
                      <el-form-item
                        label="Other affected customers"
                        prop="other_affected_users_id"
                        :rules="validateField()"
                      >
                        <eden-customers-select
                          :customers.sync="form.other_affected_users_id"
                          :multiple="true"
                          :placeholder="'Search and select the customers'"
                        />
                      </el-form-item>
                      <eden-alert :type="'info'">
                        <span
                          >There are a total of
                          <strong
                            >{{
                              form.other_affected_users_id.length + 1
                            }}
                            affected customers.</strong
                          ></span
                        >
                      </eden-alert>
                    </el-col>
                  </el-row>
                </template>

                <!-- Field: Priority Level -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Is this a very high priority issue?"
                      prop="priority"
                      :rules="validateField()"
                    >
                      <el-radio-group v-model="form.priority">
                        <el-radio :label="'high'">Yes</el-radio>
                        <el-radio :label="'low'">No</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Resolvers -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Resolver(s)"
                      prop="resolvers"
                      :rules="validateField()"
                    >
                      <eden-personnel-select
                        :personnel-type="'member'"
                        :personnels.sync="form.resolvers"
                        :placeholder="'Select at least 1 resolver'"
                        returnObject
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- Field: Status Category -->
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Status"
                  prop="status"
                  :rules="validateField()"
                >
                  <el-select v-model="form.status" placeholder="Select Status">
                    <el-option
                      v-for="(status, index) in statuses"
                      :key="index"
                      :label="status.label"
                      :value="status.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Section: Order -->
            <div v-if="orderSpecificData" class="specific-section order">
              <div class="specific-section--header">
                <p>Order details</p>
                <i class="el-icon-arrow-up" />
              </div>
              <div class="specific-section--content">
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item label="Service date">
                      <el-date-picker
                        v-model="form.order_service_date"
                        type="date"
                        format="ddd, dd MMM, yyyy"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        prefix-icon="eden-icon-calendar"
                        disabled
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item label="Order ID">
                      <el-input
                        type="text"
                        v-model="form.order_id"
                        placeholder=""
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      :label="items_label"
                      prop="order_specifics"
                      :rules="validateField()"
                    >
                      <el-select
                        v-model="form.order_specifics"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="(item, index) in items"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- Field: Service Partners -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      label="Service partner"
                      prop="service_partner"
                      :rules="validateField()"
                    >
                      <eden-personnel-select
                        :personnel-type="'service-partner'"
                        :personnel.sync="form.service_partner"
                        :placeholder="'Select the service partner'"
                        :service="form.service"
                        :multiple="false"
                        returnObject
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        @click="add"
        >Add Issue</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="update"
        >Save Changes</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import { setDepartments } from "@/components/Feedback/feedback.config";
import issues from "@/requests/feedback/issues";
import categories from "@/requests/feedback/categories";
import {
  mapCreateIssueLogDataForBackend,
  mapUpdateIssueLogDataForBackend,
} from "@/helpers/api-mappers/issues";
import {
  mealIndex
} from "@/components/Orders/Order/Meal/meal.config.js";

export default {
  name: "IssueForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    orderSpecific: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      adding: false,
      updating: false,
      orderSpecificData: this.orderSpecific,
      statuses: [
        {
          value: "not-started",
          label: "Not Started",
        },
        {
          value: "ongoing",
          label: "Ongoing",
        },
        {
          value: "blocked",
          label: "Blocked",
        },
        {
          value: "resolved",
          label: "Resolved",
        },
      ],
      form: {
        department: null,
        supporting_departments: [],
        service: "",
        description: "",
        issue_category: [],
        status: "",
        title: "",
        attachments: [],
        category: "",
        riders_id: [],
        affected_customer_type: "customer",
        affected_user: {
          id: "",
          name: "",
        },
        other_affected_user: false,
        other_affected_users_id: [],
        gardener_id: "",
        gardener: {
          id: "",
          name: "",
        },
        priority: "low",
        order_type: "one-time-order",
        resolvers_id: [],
        resolvers: [],
        order: {
          service_date: "",
          order_id: "",
          item: "",
          sp: "",
        },
        order_service_date: "",
        order_id: "",
        order_specifics: "",
        service_provider_id: "",
        service_partner: {
          id: "",
          name: "",
        },
      },
      departments: setDepartments(),
      categories: [],
      filteredCategories: [],
      items_label: "",
      items: [],
      service_partners: [],
    };
  },
  mounted() {
    this.matchForm();
  },
  created() {
    this.getCategories();
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      const val = this.$store.getters.services;

      if (
        this.form.department?.name === "Product" ||
        this.form.department?.name === "Engineering"
      ) {
        return [
          ...val,
          {
            service_name: "Not Applicable",
            slug: "",
          },
        ];
      }

      return val;
    },
    showAffectedUserType() {
      const departments = ["Product and Engineering"];
      return (
        this.form.department &&
        departments.includes(this.form.department.name) &&
        this.form.order_type === "subscription"
      );
    },
    showRiderSelection() {
      const departments = ["Supply Ops"];
      return (
        this.form.department && departments.includes(this.form.department.name)
      );
    },
  },
  watch: {
    order() {
      return this.setOrderInformation();
    },
    issue() {
      this.matchForm();
    },
  },
  methods: {
    onSelectDepartment() {
      
      if (!this.form.department || !this.form.department.id) {
        this.filteredCategories = [];
        return;
      }

      const selectedDepartment = this.departments.find(
        (department) => department.id === this.form.department.id,
      );

      if (selectedDepartment) {
        this.form.department = selectedDepartment;

        this.filteredCategories = this.categories.filter(
          (category) => category.department === selectedDepartment.name,
        );
      } else {
        this.filteredCategories = [];
      }
      console.log(this.filteredCategories, this.categories)
    },
    closeEvent() {
      this.setShow = false;
    },
    matchForm() {
      const clonedIssue = [...this.issue];
      const issueToBeMatchedObj = clonedIssue[0];
      if (issueToBeMatchedObj) {
        this.form.service = issueToBeMatchedObj?.issue_service;
        this.form.affected_user_id = issueToBeMatchedObj?.affected_user?.id;
        this.form.service_provider_id =
          issueToBeMatchedObj.service_provider_id?.id;
        // TODO: unite service_provider naming and ask backend to change service_provider_id to exclude _id
        this.form.service_partner = issueToBeMatchedObj.service_provider_id;
        this.form.gardener_id = issueToBeMatchedObj?.gardener?.id;
        this.form.department = issueToBeMatchedObj.department;
        this.onSelectDepartment(this.form.department);
        this.form.issue_category = [...issueToBeMatchedObj.issue_category];
        this.form.supporting_departments = [
          ...issueToBeMatchedObj.supporting_departments,
        ];
        this.form.description = issueToBeMatchedObj.description;
        this.form.title = issueToBeMatchedObj.title;
        this.form.attachments = issueToBeMatchedObj.attachments;
        this.form.category = issueToBeMatchedObj.issue_category;
        this.form.order_type = issueToBeMatchedObj.order_type;
        this.form.gardener = issueToBeMatchedObj.gardener;
        this.form.status = issueToBeMatchedObj.status;
        this.form.order_service_date = issueToBeMatchedObj.order_service_date;
        this.form.order_id = issueToBeMatchedObj.order_id;
        this.form.affected_user = issueToBeMatchedObj.affected_user;
        this.form.resolvers = issueToBeMatchedObj.resolvers;
        this.form.riders_id = issueToBeMatchedObj.riders_id;
        this.form.order_specifics = issueToBeMatchedObj.order_specifics;
        if (this.form.service === "meal") {
          this.items_label = "Meal combo";
        } else {
          this.items_label = `${this.formatText(this.form.service)} type`;
        }
        if (issueToBeMatchedObj.order_id !== null) {
          this.orderSpecificData = true;
        }
        if (issueToBeMatchedObj.status === "resolved") {
          this.form.resolved_by = issueToBeMatchedObj.resolved_by.id;
        }
      }
    },
    changeCategory(dept) {
      const department = this.departments.filter(
        (department) => department.name === dept.name,
      )[0];
      const departmentCategories = department.issue_categories;
      this.form.department.id = department.id;
      this.form.department.issue_categories = departmentCategories;
      this.form.issue_category = [departmentCategories[0]];
    },
    setOrderInformation() {
      const service = this.setServiceType(this.order.service);
      const departments = {
        beauty: "Beauty",
        cleaning: "Cleaning",
        laundry: "Laundry",
        meal: "Food",
      };

      this.form.department = this.departments.find((department) =>
        department.name.includes(departments[service]),
      );

      const selectedDepartment = this.departments.find(
        (department) => department.id === this.form.department.id,
      );

      if (selectedDepartment) {
        this.form.department = selectedDepartment;

        this.filteredCategories = this.categories.filter(
          (category) => category.department === selectedDepartment.name,
        );
      }
      this.form.service = service;
      const { service_breakdown: items } = this.order;
      this.form.affected_user.id = this.order.customer_id;
      this.form.affected_user.name = this.order.customer_name;
      this.form.affected_user_id = this.order.customer_id;
      this.form.gardener.id = this.order.customer_gardener.id;
      this.form.gardener.name = this.order.customer_gardener.name;
      this.form.gardener_id = this.order.customer_gardener.name;
      this.form.order.order_id = this.order.order_id;
      this.form.order_id = this.order.order_id;
      this.form.order_service_date = this.order.deliver_to_customer_date;
      this.form.order_type = !this.order.is_one_time_order
        ? "subscription"
        : "one-time-order";
      this.items = items.map((item) => this.formatToTitleCase(item.specifics));
      if (service === "meal") {
        this.items_label = "Meal combo";
        if (!this.items || this.items.length === 0) { 
        this.items = mealIndex().map((item) => {
            const mealItem = this.order[`${item}_combo`];
            return mealItem ? mealItem : null;
        }).filter(item => item !== null);      }
      } else {
        this.items_label = `${this.formatText(service)} type`;
      }
      this.form.order_specifics = this.order.item;
      this.service_partners =
        this.$store.getters.service_partners_list[service];
    },
    getCategories() {
      this.loading = true;
      categories
        .index()
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;
          if (status) {
            this.categories = data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const { logged_in_userId } = this.$store.getters.user;
        this.form.department = this.form.department.name;
        this.form.supporting_departments = this.form.supporting_departments.map(
          (department) => department.name,
        );
        this.form.attachments = this.form.attachments.map((item) => {
          return {
            name: item.name,
            url: item.url,
          };
        });
        const payload = mapCreateIssueLogDataForBackend({
          ...this.form,
          logged_in_userId,
        });
        issues
          .addIssues(payload)
          .then((response) => {
            const { status, data } = response.data;

            if (status && data.length) {
              this.adding = false;
            }
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const { logged_in_userId } = this.$store.getters.user;
        this.form.department = this.form.department.name;
        this.form.attachments = this.form.attachments.map((item) => {
          return {
            name: item.name,
            url: item.url,
          };
        });
        const payload = mapUpdateIssueLogDataForBackend({
          ...this.form,
          logged_in_userId,
          issueId: this.issueId,
        });

        if (payload.resolved_by === null) {
          delete payload.resolved_by;
        }
        if (payload.resolved_time === null) {
          delete payload.resolved_time;
        }
        if (payload.resolution === null) {
          delete payload.resolution;
        }
        issues
          .update(payload)
          .then((response) => {
            const { status, data } = response.data;
            if (status && data.length) {
              this.updating = false;
            }
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          })
          .catch((error) => {
            this.updating = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.specific-section {
  &--header {
    padding: 8px 12px;
    background: var(--eden-grey-septenary);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px;

    p {
      font-weight: 500;
      font-size: 0.875rem;
    }

    i {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  &--content {
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }

  &.close {
    .specific-section--content {
      opacity: 0 !important;
      transition: opacity 0.25s ease-in;
    }
  }
}

.el-drawer--footer {
  .el-button {
    margin-bottom: 20px;
  }
}
</style>

import axios from "axios";

export default {
  index() {
    return axios.get(`crm/issue-category`);
  },

  add(payload) {
    return axios.post(`crm/issue-category`, payload);
  },

  update(payload) {
    return axios.post(`crm/issue-category/update`, payload);
  },

  category(params = "") {
    return axios.get(`crm/issue-category${params}`);
  },

  delete(payload) {
    return axios.delete(`crm/issue-category`, { data: payload });
  }

};
